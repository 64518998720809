#skills{
    width: 100vw;
    max-width: 65rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;  
    padding-top: 0rem;
}

.skillsTitle{
    font-size: 3rem;
    font-weight: 600;    
}

.skillsDesc{
    font-weight: 400;
    font-size: 1rem;
    max-width: 50rem;
    padding: 0 2rem;

}

.skillsBars{
    margin: 1.5rem;
    width: 100vw;
    max-width: 80%;
    text-align: left;

}

.skillBar{
    display: flex;
    margin: 1rem;
    padding: 1.5rem 2rem;
    border-radius: 0.5rem;
    background:rgb(103, 103, 185);
}

.skillBarImg {
    object-fit: cover;
    height: 4rem;
    width: 4rem;
    margin-right: 2rem;


    @media screen and (max-width:400px) {
        position: absolute;
       height: 3rem;
       width: 3rem;
       
    }
}
.skillBarText>p{
    font-size: .9rem;
    font-weight: 200;
    color: white;

    @media screen and (max-width:400px) {
        padding-top: 1rem;
        
    }
}

.skillBarText>h2{
    color: white;
    text-align: left;

    @media screen and (max-width:375px) {
        text-align: center;
        
    }
    
}