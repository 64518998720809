.navbar{
    background-color:white;
    height: 5rem;
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
}

.logo{
    object-fit: cover;
    height: 4rem;
    width: 7rem;

}



.desktopMenuListItem{
    margin: 2rem;
    cursor: pointer;
}

@media screen and (max-width: 500px) {
    .desktopMenuListItem {
        margin: 1rem;
        cursor: pointer;
    }
}


.desktopMenuListItem:hover {
    color: blue;
    padding-bottom: .5rem;
    border-bottom: 3px solid blue;
}

.desktopMenuBtn {
    background: blue;
    color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 1rem;
    border-radius: 2rem;
}

.desktopMenuImg{
    object-fit: cover;
    height: 1rem;
    width: 1rem ;
    margin-left: -.5rem;
   
}


.active{
    color: blue;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid blue;
}

.mobMenu {
    display: none;
    object-fit: cover;
    height: 1.8rem;

}

.navMenu {
    position: absolute;
    top: 4rem;
    right: 2rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    height: fit-content;
    min-width: 15rem;
    background: rgb(243, 238, 238);
    border-radius: 1rem;
}

.listItem{
    color: black;
    padding: 0.5rem .5rem;
    margin: 0.25rem;
    background: rgb(243, 238, 238);

}
@media screen and (max-width: 720px) {
    .mobMenu {
        display: flex;
    }
    .desktopMenu{
        display: none;
    }
    .desktopMenuBtn {
        display: none;
        
    }
}

