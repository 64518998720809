#contact{
    overflow: hidden;
    width: 100vw;
    max-width: 65rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

.contactPageTitle{
    font-size: 3rem;
    font-weight: 600;
    padding-top: 3rem;
    margin-bottom: -10px;
    
}

.contactDesc{
    padding: 1rem;
    font-size: medium;
    font-weight: 300;

}

 .contactForm {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    max-width: 60rem;

}

.name, .email, .msg {
    font-size: medium;
    width: 100%;
    max-width: 40rem;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.5rem;
    background:rgb(212, 208, 208);
}

.submitBtn {
    background: rgb(103, 103, 185);
    color: white;
    border: none;
    padding: 0.75rem 3.5rem;
    margin: 2rem;
    border-radius: 0.5rem;
}

.links {
    display: flex;
    flex-wrap: wrap;
}

.link {
     object-fit: cover;
     height: 3rem;
     width: 3rem;
     margin: 0 0.75rem;
     
}