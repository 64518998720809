.footer{
    width: 100vw;
    height: 4rem;
    background:rgb(103, 103, 185);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    font-size: small;

}

.foot{
    color: white;
}