#intro{
    height: calc(100vh -1rem);
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

.introContent {
    height: 100vh;
    width: 100wh;
    padding: 5rem;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;

}


.bg {
    position: absolute;
    top: 1rem;
    right: 0;
    z-index: -1;
    margin-right: 5rem;
    object-fit: cover;
    height: 80vh;
    filter: drop-shadow(0 0 .75rem rgb(84, 68, 157));
    transform: translateX(60%);
    transition: transform 1s;
}


.animate{
    transform: translateX(0);
}
.hello{
    font-size: 1.50rem;
    font-weight: 100;
}

.introName {
    color: blue;
}

.btn {
    background: white;
    margin: 1rem 0;
    padding: 0.75rem 2rem;
    border: none;
    border-radius: 2rem;

}

.btnImg {
    object-fit: cover;
    margin: 0 0.25rem;
    height: 1rem;
    color: black;
}

.playStore {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    height: auto; 
  }
  
  .qr{
    width: 100%; 
    max-width: 10rem; 
    height: auto;
  }
  .dwnImg {
    width: 100%; 
    max-width: 15rem; 
    height: auto;
    padding-top: 1rem;
  }

  @media screen and (max-width:2000px ){

    .introContent{
        height:auto;
        width: 150vw;
        margin-left:10rem;
        font-size: smaller;
    }
                
    .bg{
        width: auto;
        height: 35em;
        padding-top: 2rem;
        }
    .hello{
        font-size: xx-large;
    }       
     .introText{
        font-size: 1.3rem;
        }
                
    .h6{
        font-size: 2rem;

    }}

  @media screen and (max-width:1000px ){

    .introContent{
        height:auto;
        width: 150vw;
        margin-left: -3rem;
        font-size: smaller;
    }
                
    .bg{
        width: auto;
        height: 35em;
        padding-top: 2rem;
        }
    .hello{
        font-size: large;
    }       
     .introText{
        font-size: 1.4 rem;
        }
                
    .h6{
        font-size: 2rem;

    }}

    @media screen and (max-width:800px ){

        .introContent{
            height:auto;
            width: 150vw;
            margin-left: -3rem;
            font-size: smaller;
        }
        
        .bg{
          width: auto;
          height: 20rem;
          padding-left: 5rem;
          padding-top: 3rem;
        }
    
        .introText{
            font-size: 1.3rem;
        }
        
        .h6{
            font-size: 1.5rem;
        }}

        @media screen and (max-width:600px ){

            .introContent{
                height:auto;
                width: 150vw;
                margin-left: -3rem;
                font-size: smaller;
            }
            
            .bg{
               display: none;
            }
        
            .introText{
                font-size: 1.3rem;
            }
            
            .h6{
                font-size: 2rem;
            }}
        
            .playStore { @media screen and (max-width:500px) {

                display: inline-block;
                justify-content: center;
            
            }
            .qr {
                vertical-align: middle;
                margin-left: 4rem;
            }
            
            .dwnImg {
                margin-top: -2rem;
                margin-left: 1.75rem;
            }
            }
                   


@media screen and (max-width:430px) {

    .introContent {
        padding-top: 0rem;
    }

    .hello {
        height: -2rem;
        font-size: 2rem;

}
}

@media screen and (max-width:415px) {

    .introContent {
        padding-top: 0rem;
    }

    .hello {
        height: -2rem;
        font-size: 2rem;
    
    .bg{
        background-repeat: no-repeat;
        position: absolute;
        animation: slide 6s linear infinite;
    }
}    
}



